import React from 'react'
import Link from 'gatsby-link'
import Layout from '../../../components/layout'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import acomLogo from './ancestry-logo.svg'
import phoneEvolution from './ancestry-phone-evolution.png'
import personEvolution from './ancestry-person-evolution.png'
import dnaEvolution from './ancestry-dna-evolution.png'
import hintLeaf from './hint-leaf.svg'

import iphoneProfile from './iphone-profile.png'
import iphoneTree from './iphone-tree.png'
import iphoneEthnicity from './iphone-ethnicity.png'
import iphoneMigration from './iphone-migrations.png'

import desktopProfile from './desktopProfile.png'
import desktopTree from './desktopTree.png'
import desktopEthnicity from './desktopEthnicity.png'
import desktopMigration from './desktopMigrations.png'
import styled from '@emotion/styled'

const RED = styled.span`
  color: #c00;
`

const H4 = styled.h4`
 text-transform: uppercase;
 letter-spacing: .05em;
 color: #c00;
`
const Stack = styled.div`
  margin-top:40px;
  background-color: #efefef;
`


const CatTitle = styled.p`
font-size: .75em;
text-transform: uppercase;
color: #000;
opacity: .5;
letter-spacing: .1  em;
`

const H1 = styled.h1`
margin-top: 0;
`

export default () => (
  <Layout>
  <PageTransition>
  <BodyClassName className="">
  <div className="page">

  <article className="">

  <section className="contentSection">
    <div className="row">
      <div className="col-xs-9">
      <header>
      <Link to="./casestudies"><CatTitle>Case Study</CatTitle></Link>
        <H1>Evolving Ancestry Towards Consistency</H1>
      </header>
      </div>
    </div>

    <div className="row">
      <div className="col-xs-12 col-sm-7 col-md-8">
        <H4>The Problem</H4>
        <p className="introText">
          Ancestry first launched in 1996 and experienced consistent growth for over 20 years. Product features added over this period were developed in silos, without a consistent interface, and without a  strategy to align visual design across the product.
        </p>
        <p className="introText">Stylistic variation across the site often resulted in a poor experience for customers who complained and were often confused by variation in style and design.</p>

      <H4>Solution</H4>
        <p className="introText">
          Just as the problem was created over multiple years, the solution to this problem required ongoing dedication, persistence, and effort over many years.  </p>
          <p className="introText">The first step began in 2015 with the development of new design guidelines. Over a period of 5 years, we steadily evolved the design system and moved the primary product elements toward consistency on both web and mobile.
        </p>
          <p><small><em>Many designers under my direction were involved in this effort. Rob Foster, Kory Garner, David Delphenich, Brooke Alius, Jason Boyer and others deserve credit for their work.</em></small></p>
      </div>

      <div className="col-xs-12 col-sm-5 col-md-4">
        <ScrollAnimation animateIn="fadeIn" duration={.5}>
          <p><img className="full" src={acomLogo} style={{"maxWidth":"300px"}} alt="Ancestry"/></p>
          
          <ul>
            <li>Directed and developed comprehensive design system and guidelines in 2015.</li>
            <li>I oversaw the design rollout to over 2.5 million subscribing customers.</li>
            <li>Managed customer feedback, personally speaking with dozens of customers to identify issues or complaints with new changes.</li>
            <li>Established working group among designers and front-end developers to continually evolve the design system over time.</li>
            <li>Adpated design system to improve product, support new product features, and meet accessibilty targets.</li>
            <li>Directed team of designers dedicated to the effort.</li>
            
            
            <li>I worked as a director and as an individual contributor wherever I was needed.</li>
          </ul>
        </ScrollAnimation>
      </div>

    </div>

    </section>


    <div className="mt-40" style={{"background":"#5e6770", "color":"#fff"}}>
      <section className="contentSection">
  <h2 className="metalab-heading"><span className="numbers">1</span> Providing Design Guidlines & A Pattern Library</h2>
      <div className="row">
      <div className="col-xs-12 col-sm-7">

      <p>To provide the tools to a growing team of designers and developers and to ensure that our designs met a baseline consistency, we expanded and improved Ancestry’s existing design components.</p>
      <p>I started a “standards committee” of designers and developers charged with addressing the needs of our design system, making ongoing updates to support performance, accessibility, and consistency goals across the team.</p>

      <p><Link to="/casestudy/ancestry-bonsai" className="link"><small>View case study for development of Ancestry's design system.</small></Link></p>
      </div>
      <div className="col-xs-12 col-sm-5 p-40">
        <ScrollAnimation animateIn="jackInTheBox" duration={.5}>
          <img src={hintLeaf} alt="" className="full"/>
          </ScrollAnimation>
        </div>
      </div>
      </section>
    </div>


    <div className="" >
      <section className="contentSection">
      <h2 className="metalab-heading"><span className="numbers">2</span>Mobile App Evolution</h2>
      <div className="row">
      <div className="col-xs-12 col-md-4">
      <p>From skeuomorphic to flat, the Ancestry’s mobile app has become well integrated and still leads the way for product designs and features in Ancestry's products.</p>
      <p>The mobile app has retained a near 5-star rating for five years and nearly contains all the functionality of Ancestry’s web properties</p>
    </div>
      <div className="col-xs-12 col-md-8 first-md">
      <ScrollAnimation animateIn="fadeIn" duration={.5}>
        <img src={phoneEvolution} className="full" alt=""/>
        </ScrollAnimation>
      </div>
      </div>
    </section>
    </div>

<div style={{"background":"#efefef"}}>
    <section className="contentSection" >

      <h2 className="metalab-heading"><span className="numbers">3</span> Ancestor Profile Evolution</h2>
      <div className="row">
      <div className="col-xs-12 col-md-4">

      <p>The Ancestor Profile is the most visited page on Ancestry and is at the center of nearly everything that customers use on the site.</p>
      <p>Changes to this page required detailed research, field studies, and tight coordination with marketing and customer support teams to ensure that changes did not disrupt customers.</p>


      </div>
      <div className="col-xs-12 col-md-8">
      <ScrollAnimation animateIn="fadeIn" duration={.5}>
        <img src={personEvolution} className="full" alt="" />
        </ScrollAnimation>
      </div>
      </div>

    </section>
    </div>


    <section className="contentSection">
      <h2 className="metalab-heading"><span className="numbers">4</span> Ancestry DNA Evolution</h2>
      <div className="row">
      <div className="col-xs-12 col-md-4">



        <p> Customers who used both AncestryDNA and Ancestry's Family History products often expressed that each felt like a different company due to visual treatment and segregated functionality.</p>

        <p>In August of 2018, after having the DNA product as part of my responsibility, we released an update that brought the two interfaces in alignment.</p>

      </div>
      <div className="col-xs-12 col-md-8 first-xs">
      <ScrollAnimation animateIn="fadeIn" duration={.5}>
        <img className="full" src={dnaEvolution} alt=""/>
        </ScrollAnimation>
      </div>
      </div>
    </section>


    <div style={{"background":"#efefef", "overflow":"hidden"}}>
    <section className="contentSection">
    <h2 className="metalab-heading">Integrated Design Across Mobile & Desktop</h2>
    <p>It took several years and persistence to achieve an integrated design when working with legacy software. Ancestry still needs many improvements, but I have been satisfied with the development and consistency we've achieved.</p>
      <div className="row">
        <div className="col-xs-6 col-md-3">
          <ScrollAnimation animateIn="fadeInUp2" duration={.5} >
            <img className="full" src={iphoneProfile} alt=""/>
          </ScrollAnimation>
        </div>
        <div className="col-xs-6 col-md-3" >
          <ScrollAnimation animateIn="fadeInUp2" duration={.5} delay={100} >
            <img className="full" src={iphoneTree} alt=""/>
          </ScrollAnimation>
        </div>
        <div className="col-xs-6 col-md-3" >
          <ScrollAnimation animateIn="fadeInUp2" duration={.5} delay={200} >
            <img  className="full" src={iphoneEthnicity} alt=""/>
          </ScrollAnimation>
        </div>
        <div className="col-xs-6 col-md-3">
          <ScrollAnimation animateIn="fadeInUp2" duration={.5} delay={300} >
            <img  className="full" src={iphoneMigration} alt=""/>
          </ScrollAnimation>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <ScrollAnimation animateIn="fadeInUp2" duration={.5} >
            <img  className="full" src={desktopProfile} alt=""/>
          </ScrollAnimation>
        </div>
        <div className="col-xs-12 col-md-6" >
          <ScrollAnimation animateIn="fadeInUp2" duration={.5} delay={100} >
            <img  alt="" className="full" src={desktopTree}/>
          </ScrollAnimation>
        </div>
      </div>

        <div className="row">
          <div className="col-xs-12 col-md-6" >
            <ScrollAnimation animateIn="fadeInUp2" duration={.5} delay={200} >
              <img  className="full" src={desktopEthnicity} alt=""/>
            </ScrollAnimation>
          </div>
        <div className="col-xs-12 col-md-6">
          <ScrollAnimation animateIn="fadeInUp2" duration={.5} delay={300} >
            <img  className="full" src={desktopMigration} alt=""/>
          </ScrollAnimation>
        </div>
      </div>
      </section>
      </div>

    </article>

  </div>

  </BodyClassName>
  </PageTransition>
  </Layout>
)
